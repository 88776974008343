import React from 'react'
import { ThemeProvider, theme } from '@papertrail/styleguide'
import DocumentViewer from './components/DocumentViewer'
import { BrowserRouter } from 'react-router-dom'

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <DocumentViewer />
      </BrowserRouter>
    </ThemeProvider>
  )
}
