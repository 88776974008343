import React, { useCallback, useEffect, useState } from 'react'

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  ButtonPrimary,
  ButtonSecondary
} from '@papertrail/styleguide'

export default function DocumentViewer() {
  const [open, setOpen] = useState(false)
  const [docUrl, setDocUrl] = useState('')
  const [mode, setMode] = useState<any>()

  useEffect(() => {
    addEventListener('showDocument', (e) => {
      const docUrl = (e as any).detail.docUrl
      const mode = (e as any).detail.mode

      setMode(mode)
      setDocUrl(docUrl)
      setOpen(true)
    })
  }, [])

  function onCancel() {
    setOpen(false)
  }

  function onClick() {
    dispatchEvent(new CustomEvent('shareDocument', { detail: { docUrl, mode } }))
  }

  const handleEscapeKeyPress = useCallback((event) => {
    if (event.key === 'Escape') {
      onCancel()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKeyPress)

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [])

  if (open) {
    return (
      <Dialog open={true} maxWidth="xl">
        <DialogTitle>Share {mode}</DialogTitle>
        <DialogContent>
          <iframe style={{ width: '1000px', height: '500px', border: 'none' }} src={decodeURIComponent(docUrl)} />
        </DialogContent>
        <DialogActions>
          <ButtonSecondary onClick={onCancel}>Close</ButtonSecondary>
          <ButtonPrimary onClick={onClick}>Share</ButtonPrimary>
        </DialogActions>
      </Dialog>
    )
  } else {
    return null
  }
}
